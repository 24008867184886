export const exchange_rates = {
  name: "Exchange Rates",
  slug: "exchange_rates",
  fields: [
    {
      key: "otherCurrency",
      type: "string",
      label: "Other currency",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
    {
      key: "RateToBaseCurrency",
      type: "string",
      label: "Rate to base currency",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
    {
      key: "EffectiveFrom",
      type: "string",
      label: "Effective From",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
  ],
};
