import React, { useContext, useState, useEffect } from 'react'; 
import { Form, Button, Table, Container, Row, Col, Card, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './SettingsPage.css';
import axios from 'axios';
import config from './config';


const renderCustomTooltip = (message) => (
    <Tooltip id="custom-tooltip">
        <div
            style={{ textAlign: 'left' }}
            dangerouslySetInnerHTML={{ __html: message.split('\n\n').join('<br/><br/>') }}
        />
    </Tooltip>
);

const renderTooltip = (message) => (
    <Tooltip id="tooltip">
        <div
            style={{ textAlign: 'left' }}
            dangerouslySetInnerHTML={{ __html: message.split('\n\n').join('<br/><br/>') }}
        />
    </Tooltip>
);

const SettingsPage = () => {
    const companyData = JSON.parse(localStorage.getItem('companyData'));
    const userData = JSON.parse(localStorage.getItem('userData'));
    const [activeTab, setActiveTab] = useState('company');
    const [dateFormat, setDateFormat] = useState(companyData.date_format);
    const [currency, setCurrency] = useState(companyData.base_currency);
    const [currencies, setCurrencies] = useState([]);
    const [historyLength, setHistoryLength] = useState(companyData.history_length);
    const [companyId] = useState(companyData.id);
    const [companyName] = useState('Digilynx');
    const [companyLogo] = useState(companyData.company_logo);
    const [emailAddress, setEmailAddress] = useState(companyData.company_email);
    const [userLimit, setUserLimit] = useState(companyData.user_limit);
    const [users, setUsers] = useState(userData);
    const [newUserEmail, setNewUserEmail] = useState('');
    const [newUserRole, setNewUserRole] = useState('');
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [showLimitReachedPopup, setShowLimitReachedPopup] = useState(false);

    useEffect(() => {
        const initializeSettings = async () => {
            await fetchCurrencies();
        };
        initializeSettings();
    }, []);

    // Handle tab switch
    const handleTabSwitch = (tab) => {
        setActiveTab(tab);
    };

    const fetchCurrencies = async () => {
        try {
            const response = await axios.get(`${config.API_URL}/fetch_currencies.php`);
            const data = response.data;
    
            // Ensure the response is an array before setting it
            if (Array.isArray(data)) {
                setCurrencies(data);
            } else {
                console.error("Invalid currencies data:", data);
                setCurrencies([]); // Set to an empty array to prevent errors
            }
        } catch (error) {
            console.error("Error fetching currencies:", error);
            setCurrencies([]); // Set to an empty array on error
        }
    };

    const handleSaveConfiguration = async () => {
        try {
            await axios.post(`${config.API_URL}/save_company.php?company_id=${companyId}`, {
                dateFormat,
                baseCurrency: currency,
                historyLength
            });
            alert('Configuration saved successfully');
        } catch (error) {
            console.error("Error saving configuration:", error);
        }
    };

    const handleAddUser = async () => {
        if (users.length >= userLimit) {
            setShowLimitReachedPopup(true);
            return;
        }
    
        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(newUserEmail)) {
            alert("Please enter a valid email address.");
            return;
        }
    
        console.log("Adding user with role:", newUserRole);
    
        try {
            const response = await axios.post(`${config.API_URL}/add_user.php`, {
                email: newUserEmail,
                role: newUserRole,
                companyId
            });
            setUsers([...users, response.data]);
            setNewUserEmail('');
            setShowAddUserModal(false);
        } catch (error) {
            console.error("Error adding new user:", error);
        }
    };    

    const handleToggleActiveStatus = async (userId) => {
        console.log('ada');
        // Check if users is an array
        if (!Array.isArray(users)) return;
        // Find the specific user
        const user = users.find(user => user.id === userId);
        if (!user) return;
        console.log(user);
        const newStatus = user.status === 'Active' ? 'Inactive' : 'Active';
    
        try {
            // Make the API call to update the status in the database
            const response = await axios.post(`${config.API_URL}/update_user_status.php?userId=${userId}`, { active_status: newStatus });
    
            // Log the response for debugging
            console.log("API response:", response.data);
    
            // Only update the state if the API call is successful
            if (response.data.success) {
                setUsers(prevUsers =>
                    prevUsers.map(user =>
                        user.id === userId ? { ...user, status: newStatus } : user
                    )
                );
            } else {
                console.error("Failed to update status:", response.data.error || "Unknown error");
            }
        } catch (error) {
            console.error("Error updating user status:", error);
        }
    };
    
    const handleChangeUserRole = async (userId, role) => {
        try {
            await axios.post(`${config.API_URL}/update_user_role.php?userId=${userId}`, { role });
            setUsers(users.map(user =>
                user.id === userId ? { ...user, role } : user
            ));
        } catch (error) {
            console.error("Error updating user role:", error);
        }
    };

    const handleCloseLimitPopup = () => {
        setShowLimitReachedPopup(false);
    };

    return (
        <Container className="mt-4 settingsPage">
            <Row>
                <Col>
                    <div className="tabs">
                        <Button variant={activeTab === 'company' ? 'primary' : 'outline-primary'} onClick={() => handleTabSwitch('company')} className="button-tab"> Company </Button>
                        <Button variant={activeTab === 'users' ? 'primary' : 'outline-primary'} onClick={() => handleTabSwitch('users')} className="button-tab"> Users </Button>
                    </div>
                </Col>
            </Row>

            {activeTab === 'company' && (
                <Card className="p-4">
                    <Form className="company-config">
                        <Container>
                            <Row>
                                {/* Editable Fields (Left Column) */}
                                <Col md={6}>
                                    <Form.Group className='company-config-field' controlId="formDateFormat">
                                        <Form.Label>Date Format</Form.Label>
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={renderTooltip("The date format used in your business can be either dd/mm/yyyy or mm/dd/yyyy, depending on your preference.")}
                                        >
                                            <i className="fas fa-info-circle " style={{ cursor: 'pointer' }}></i>
                                        </OverlayTrigger>
                                        <Form.Control
                                            as="select"
                                            value={dateFormat}
                                            onChange={(e) => setDateFormat(e.target.value)}
                                        >
                                            <option value="dd/mm/yyyy">dd/mm/yyyy</option>
                                            <option value="mm/dd/yyyy">mm/dd/yyyy</option>
                                        </Form.Control>
                                    </Form.Group>
        
                                    <Form.Group className="mt-4 company-config-field" controlId="formCurrency">
                                        <Form.Label>Base Currency</Form.Label>
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={renderTooltip("Please choose a single base currency for your business. This will be used for all money-based metrics, even if you sell in multiple markets with different currencies.")}
                                        >
                                            <i className="fas fa-info-circle " style={{ cursor: 'pointer' }}></i>
                                        </OverlayTrigger>
                                        <Form.Control
                                            as="select"
                                            value={currency}
                                            onChange={(e) => setCurrency(e.target.value)}
                                        >
                                            {currencies.map((curr) => (
                                                <option key={curr.id} value={curr.currency_code}>
                                                    {curr.currency_code} - {curr.currency_name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
        
                                    <Form.Group className="mt-4 company-config-field" controlId="formHistoryLength">
                                        <Form.Label>History Length</Form.Label>
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={renderTooltip("Select how much historical data you want to include in your reports. This will determine the time range of past data shown.")}
                                        >
                                            <i className="fas fa-info-circle " style={{ cursor: 'pointer' }}></i>
                                        </OverlayTrigger>
                                        <Form.Control
                                            as="select"
                                            value={historyLength}
                                            onChange={(e) => setHistoryLength(e.target.value)}
                                        >
                                            <option value="All">All</option>
                                            <option value="1 year">1 year</option>
                                            <option value="3 years">3 years</option>
                                            <option value="5 years">5 years</option>
                                            <option value="10 years">10 years</option>
                                        </Form.Control>
                                    </Form.Group>
                                    
                                    <Form.Group className="mt-4 button-container" controlId="submitButton">
                                        <Button variant="primary" onClick={handleSaveConfiguration}>
                                            Save Configuration
                                        </Button>
                                    </Form.Group>
                                </Col>
        
                                {/* Divider */}
                                <Col md={1} className="d-flex justify-content-center align-items-center">
                                    <div style={{ borderLeft: '1px solid #ccc', height: '100%' }}></div>
                                </Col>
        
                                {/* Read-Only Fields (Right Column) */}
                                <Col className='company-info' md={5}>
                                    <Form.Group className='single-field' controlId="formCompanyName">
                                        <Form.Label>{companyName}</Form.Label>
                                        <p>
                                            <img src={companyLogo} alt="Company Logo" />
                                        </p>
                                    </Form.Group>

                                    <Form.Group className='single-field' controlId="formCompanyId">
                                        <Form.Label>Company ID</Form.Label>
                                        <p>{companyId}</p>
                                    </Form.Group>

                                    <Form.Group className='single-field' controlId="formEmailAddress">
                                        <Form.Label>Account Holder's Email Address</Form.Label>
                                        <p>{emailAddress}</p>
                                    </Form.Group>

                                    <Form.Group className='single-field' controlId="formUserLimit">
                                        <Form.Label>Number of Users Permitted</Form.Label>
                                        <p>{userLimit}</p>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                </Card>
            )}

            {activeTab === 'users' && (
                <Card className="p-3">
                    <Table striped bordered hover responsive style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                        <thead>
                            <tr>
                                <th>Email</th>
                                <th className="role-header">Role</th> {/* Add class to the Role header */}
                                <th>Active</th>
                            </tr>
                        </thead>

                        <tbody>
                            {Array.isArray(users) && users.length > 0 ? (
                                users.map((user) => (
                                    <tr key={user.id}>
                                        <td>
                                            <div className="email-column">
                                                {user.email}
                                                {/* On mobile, show the role dropdown under the email */}
                                                <Form.Control 
                                                    as="select"
                                                    value={user.role}
                                                    className="user-role-mobile-select"
                                                    onChange={(e) => handleChangeUserRole(user.id, e.target.value)} 
                                                >
                                                    <option value="viewer">Viewer</option>
                                                    <option value="collaborator">Collaborator</option>
                                                    <option value="admin">Admin</option>
                                                </Form.Control>
                                            </div>
                                        </td>
                                        <td className="role-column">
                                            <Form.Control 
                                                as="select" 
                                                value={user.role}
                                                onChange={(e) => handleChangeUserRole(user.id, e.target.value)} 
                                            >
                                                <option value="viewer">Viewer</option>
                                                <option value="collaborator">Collaborator</option>
                                                <option value="admin">Admin</option>
                                            </Form.Control>
                                        </td>
                                        <td className='checkbox-container'>
                                            <input 
                                                type="checkbox" 
                                                checked={user.status === 'Active'} 
                                                onChange={() => handleToggleActiveStatus(user.id)} 
                                            />
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <div>No users found</div>
                            )}
                        </tbody>


                    </Table>

                    {/* Add New User Button */}
                    <Button 
                        variant="primary" 
                        onClick={() => setShowAddUserModal(true)} 
                        className="mt-3" 
                        style={{ width: '200px', alignSelf: 'end' }} // Set your desired height here
                    >
                        Add New User
                    </Button>

                    {/* Modal for Adding New User */}
                    <Modal className='add-new-user-modal' show={showAddUserModal} onHide={() => setShowAddUserModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add New User</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group controlId="formNewUserEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control 
                                        type="email" 
                                        value={newUserEmail}
                                        onChange={(e) => setNewUserEmail(e.target.value)}
                                        placeholder="Enter user email"
                                    />
                                </Form.Group>

                                <Form.Group controlId="formNewUserRole">
                                    <Form.Label>Role</Form.Label>
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={renderCustomTooltip(
                                            "Choose a user role to define access levels:<br/><br/>" +
                                            "- <strong>Admin:</strong> Full access to read, write, and edit data.<br/>" +
                                            "- <strong>Collaborator:</strong> Can read and edit data but cannot manage settings.<br/>" +
                                            "- <strong>Viewer:</strong> Can only view data without making any changes."
                                        )}
                                    >
                                        <i className="fas fa-info-circle ms-2" style={{ cursor: 'pointer' }}></i>
                                    </OverlayTrigger>

                                    <Form.Control 
                                        as="select" 
                                        value={newUserRole} 
                                        onChange={(e) => setNewUserRole(e.target.value)}
                                    >
                                        <option value="viewer">Viewer</option>
                                        <option value="collaborator">Collaborator</option>
                                        <option value="admin">Admin</option>
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleAddUser} style={{ width: 'auto' }}>
                                Send Invitation
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal className='limit-reached' show={showLimitReachedPopup} onHide={handleCloseLimitPopup}>
                        <Modal.Header closeButton>
                            <Modal.Title>User Limit Reached</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>No other users can be added until the number of permitted users has increased.</p>
                            <p>Please contact <b>n.brown@demo.com</b> to increase the number of user licenses.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleCloseLimitPopup}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Card>
            )}
        </Container>
    );
};

export default SettingsPage;
