import React from 'react';
import './ReportsPage.css';

const ReportsPage = () => {
    return (
        <div className="reports-container">
            <div className="main-content">
                <img 
                    src="/reports.png" 
                    alt="Reports" 
                    className="full-screen-screenshot" 
                />
            </div>
        </div>
    );
};

export default ReportsPage;
