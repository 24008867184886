import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Table,
    Modal,
    Button,
    Form,
    Container,
    Row,
    Col,
    Card,
    Spinner,
    Alert,
} from "react-bootstrap";
import './DatabaseManagementPage.css';
import config from './config';
import { useAuth0 } from '@auth0/auth0-react';

const DatabaseManagementPage = () => {
    const { user, isAuthenticated } = useAuth0();
    const [tables, setTables] = useState([]);
    const [selectedTable, setSelectedTable] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [editRow, setEditRow] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false); // New modal for adding records
    const [newRecord, setNewRecord] = useState({}); // New state for new record data
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [accessDenied, setAccessDenied] = useState(false);
    const [authLoading, setAuthLoading] = useState(true);

    const showAlert = (message, variant = "success") => {
        setAlertMessage({ message, variant });
        setTimeout(() => setAlertMessage(null), 5000); // Clear alert after 5 seconds
    };

    useEffect(() => {
        if (isAuthenticated && user?.email) {
            axios
                .get(`${config.API_URL}/admin_rights.php`, { params: { email: user.email } })
                .then((response) => {
                    if (response.data.success) {
                        setAccessDenied(false);
                        fetchTables();
                    } else {
                        setAccessDenied(true);
                        console.log(response.data.error || 'Access denied.');
                    }
                })
                .catch((err) => {
                    console.error("Error checking admin rights:", err);
                    setAccessDenied(true);
                })
                .finally(() => {
                    setAuthLoading(false);
                });
        } else {
            setAuthLoading(false);
            setAccessDenied(true);
        }
    }, [isAuthenticated, , user]);

    const fetchTables = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${config.API_URL}/get_tables.php`);
            setTables(response.data.tables);
            showAlert("Tables fetched successfully.");
        } catch (error) {
            console.error("Error fetching tables:", error);
            showAlert("Failed to fetch tables.", "danger");
        } finally {
            setLoading(false);
        }
    };

    // Fetch data for the selected table
    const fetchTableData = async (table) => {
        setLoading(true);
        try {
            const response = await axios.get(`${config.API_URL}/get_table_data.php?table=${table}`);
            setSelectedTable(table);
            setTableData(response.data.records);
        } catch (error) {
            console.error("Error fetching table data:", error);
            showAlert("Failed to fetch table data.", "danger");
        } finally {
            setLoading(false);
        }
    };

    // Handle row edit
    const handleEdit = (row) => {
        setEditRow(row);
        setShowEditModal(true);
    };

    // Handle save after editing
    const handleSaveEdit = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${config.API_URL}/update_record.php`, {
                table: selectedTable,
                data: editRow,
            });
            if (response.data.success) {
                fetchTableData(selectedTable); // Refresh table data
                setShowEditModal(false); // Close modal
                showAlert("Record updated successfully.");
            } else {
                showAlert("Failed to update record.", "danger");
            }
        } catch (error) {
            console.error("Error updating record:", error);
            showAlert("Error updating record.", "danger");
        } finally {
            setLoading(false);
        }
    };

    // Handle row deletion
    const handleDelete = async (idOrCompanyId) => {
        setLoading(true);
        try {
            const payload = {
                table: selectedTable,
                id: selectedTable === "ReportAccess" ? { company_id: idOrCompanyId } : idOrCompanyId,
            };

            const response = await axios.post(`${config.API_URL}/delete_record.php`, payload);

            if (response.data.success) {
                fetchTableData(selectedTable); // Refresh table data
                showAlert("Record deleted successfully.");
            } else {
                showAlert("Failed to delete record.", "danger");
            }
        } catch (error) {
            console.error("Error deleting record:", error);
            showAlert("Error deleting record.", "danger");
        } finally {
            setLoading(false);
        }
    };

    // Handle new record form submission
    const handleAddNewRecord = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${config.API_URL}/insert_record.php`, {
                table: selectedTable,
                data: newRecord,
            });
            if (response.data.success) {
                fetchTableData(selectedTable); // Refresh table data
                setShowAddModal(false); // Close modal
                showAlert("New record added successfully.");
            } else {
                showAlert("Failed to add new record.", "danger");
            }
        } catch (error) {
            console.error("Error adding new record:", error);
            showAlert("Error adding new record.", "danger");
        } finally {
            setLoading(false);
        }
    };

    // Handle form input for new record
    const handleNewRecordChange = (e) => {
        const { name, value } = e.target;
        setNewRecord({ ...newRecord, [name]: value });
    };
    
    if (authLoading) {
        // Show loader while checking authorization
        return (
            <Container className="text-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <p>Checking authorization, please wait...</p>
            </Container>
        );
    }

    if (accessDenied) {
        return (
            <Container className="text-center mt-5">
                <Alert variant="danger">
                    You do not have access to view this page. <br />
                    Please log in with <strong> support@pivotal-dss.com</strong> to proceed.                
                </Alert>
            </Container>
        );
    }

    return (
        <div>
            {/* Page Content */}
            <Container>
                {/* Alert Message */}
                {alertMessage && (
                    <Alert
                        variant={alertMessage.variant}
                        className="mb-3 text-center"
                        dismissible
                        onClose={() => setAlertMessage(null)}
                    >
                        {alertMessage.message}
                    </Alert>
                )}

                <Row>
                    {/* Tables List */}
                    <Col md={3}>
                        <Card>
                            <Card.Header className="bg-primary text-white">
                                <h5>Select Table</h5>
                            </Card.Header>
                            <Card.Body>
                                {loading && !selectedTable ? (
                                    <div className="text-center">
                                        <Spinner animation="border" />
                                    </div>
                                ) : tables.length > 0 ? (
                                    tables.map((table, index) => (
                                        <Button
                                            key={index}
                                            className="w-100 mb-2 text-start table-name"
                                            onClick={() => fetchTableData(table)}
                                            variant={selectedTable === table ? "success" : "outline-primary"}
                                        >
                                            <i className="fa-solid fa-table"></i>  {table}
                                        </Button>
                                    ))
                                ) : (
                                    <p>No tables available.</p>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* Table Data */}
                    <Col md={9}>
                        {loading && selectedTable ? (
                            <div className="text-center mt-5">
                                <Spinner animation="border" />
                            </div>
                        ) : selectedTable ? (
                            <Card>
                                <Card.Header className="bg-secondary text-white db-management">
                                    <h5>Table: {selectedTable}</h5>
                                    <Button 
                                        variant="success" 
                                        className="float-end" 
                                        onClick={() => setShowAddModal(true)}
                                    >
                                        Add New Record
                                    </Button>
                                </Card.Header>
                                <Card.Body>
                                    {tableData.length > 0 ? (
                                        <div className="table-responsive">
                                            <Table className="table-bordered table-hover custom-table">
                                                <thead className="table-dark">
                                                    <tr>
                                                        {Object.keys(tableData[0]).map((col, index) => (
                                                            <th key={index}>{col}</th>
                                                        ))}
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableData.map((row, rowIndex) => (
                                                        <tr key={rowIndex}>
                                                            {Object.values(row).map((value, colIndex) => (
                                                                <td key={colIndex}>{value}</td>
                                                            ))}
                                                            <td>
                                                                <Button
                                                                    variant="warning"
                                                                    size="sm"
                                                                    className="me-2"
                                                                    onClick={() => handleEdit(row)}
                                                                >
                                                                    <i className="fa-solid fa-pencil"></i>
                                                                </Button>
                                                                <Button
                                                                    variant="danger"
                                                                    size="sm"
                                                                    onClick={() =>
                                                                        handleDelete(selectedTable === "ReportAccess" ? row.company_id : (row.id || row.ID))
                                                                    }                                                                
                                                                >
                                                                    <i className="fa-solid fa-trash"></i>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    ) : (
                                        <p>No data available for the selected table.</p>
                                    )}
                                </Card.Body>
                            </Card>
                        ) : (
                            <p>Please select a table to view data.</p>
                        )}
                    </Col>
                </Row>
            </Container>

            {/* Add New Record Modal */}
            <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Record</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedTable && tableData.length > 0 && (
                        <>
                            {Object.keys(tableData[0]).map((key, index) => (
                                // Show 'id' field only for the companies table
                                selectedTable === 'companies' || key !== 'id' ? (
                                    <Form.Group key={index} className="mb-3">
                                        <Form.Label>{key}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={key}
                                            value={newRecord[key] || ''}
                                            onChange={handleNewRecordChange}
                                        />
                                    </Form.Group>
                                ) : null
                            ))}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAddModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAddNewRecord}>
                        Add Record
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Modal */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Record</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {editRow &&
                        Object.keys(editRow).map((key, index) => (
                            <Form.Group key={index} className="mb-3">
                                <Form.Label>{key}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={editRow[key]}
                                    onChange={(e) =>
                                        setEditRow({
                                            ...editRow,
                                            [key]: e.target.value,
                                        })
                                    }
                                />
                            </Form.Group>
                        ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveEdit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default DatabaseManagementPage;
