import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from './config';
import { useAuth0 } from '@auth0/auth0-react';

const CurrenciesPage = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();
    const [currencyCode, setCurrencyCode] = useState('');
    const [currencyName, setCurrencyName] = useState('');
    const [currencies, setCurrencies] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [accessDenied, setAccessDenied] = useState(false);

    useEffect(() => {
        const allowedEmails = [
            'support@pivotal-dss.com', 
            'mansoor@digilynx.dev', 
          ];

        if (!isLoading) { 
            if (isAuthenticated && !allowedEmails.includes(user?.email)) {
                setAccessDenied(true);
            } else {
                setAccessDenied(false);
                fetchCurrencies();
            }
        }
    }, [isAuthenticated, isLoading, user]);

    const fetchCurrencies = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.get(`${config.API_URL}/get_currencies.php`);
            setCurrencies(response.data.currencies || []);
        } catch (err) {
            setError('Failed to fetch currencies. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleAddCurrency = async () => {
        if (!currencyCode || !currencyName) {
            setError('Both fields are required.');
            return;
        }
        setError('');
        setLoading(true);
        try {
            const response = await axios.post(`${config.API_URL}/add_currency.php`, {
                currency_code: currencyCode,
                currency_name: currencyName,
            });
            if (response.data.success) {
                fetchCurrencies(); // Refresh the table
                setCurrencyCode('');
                setCurrencyName('');
                setShowModal(false); // Close modal
            } else {
                setError(response.data.message || 'Failed to add currency.');
            }
        } catch (err) {
            setError('Error adding currency. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteCurrency = async (id) => {
        setError('');
        setLoading(true);
        try {
            const response = await axios.post(`${config.API_URL}/delete_currency.php`, { id });
            if (response.data.success) {
                fetchCurrencies(); // Refresh the table
            } else {
                setError(response.data.message || 'Failed to delete currency.');
            }
        } catch (err) {
            setError('Error deleting currency. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    if (accessDenied) {
        return (
            <div className="container mt-5">
                <div className="alert alert-danger">
                    You do not have access to view this page. <br /> 
                    Please log in with <strong> support@pivotal-dss.com</strong> to proceed.
                </div>
            </div>
        );
    }

    return (
        <div className="container mt-5">
            <h1 className="mb-4">Manage Currencies</h1>

            <button
                className="btn btn-primary mb-4"
                onClick={() => setShowModal(true)}
            >
                Add New Currency
            </button>

            {error && <div className="alert alert-danger">{error}</div>}
            {loading && (
                <div className="d-flex justify-content-center mb-4">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}

            <h2 style={{ color: '#282828' }}>Currency List</h2>
            <table className="table table-bordered">
                <thead className="thead-dark">
                    <tr>
                        <th>#</th>
                        <th>Currency Code</th>
                        <th>Currency Name</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currencies.length > 0 ? (
                        currencies.map((currency, index) => (
                            <tr key={currency.id}>
                                <td>{index + 1}</td>
                                <td>{currency.currency_code}</td>
                                <td>{currency.currency_name}</td>
                                <td>
                                    <button
                                        className="btn btn-danger btn-sm"
                                        onClick={() => handleDeleteCurrency(currency.id)}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4" className="text-center">
                                No currencies found.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Modal for Adding Currency */}
            {showModal && (
                <div className="modal show d-block" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add New Currency</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => setShowModal(false)}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    placeholder="Currency Code"
                                    value={currencyCode}
                                    onChange={(e) => setCurrencyCode(e.target.value)}
                                />
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    placeholder="Currency Name"
                                    value={currencyName}
                                    onChange={(e) => setCurrencyName(e.target.value)}
                                />
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleAddCurrency}
                                    disabled={loading}
                                >
                                    {loading ? (
                                        <div
                                            className="spinner-border spinner-border-sm text-light"
                                            role="status"
                                        ></div>
                                    ) : (
                                        'Add Currency'
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CurrenciesPage;
