export const products = {
  name: "Products",
  slug: "products",
  fields: [
    {
      key: "ID",
      type: "string",
      label: "ID",
      constraints: [
        {
          "type": "unique"
        }
      ],
    },
    {
      key: "Name",
      type: "string",
      label: "Name",
    },
    {
      key: "Description",
      type: "string",
      label: "Description",
    },
    {
      key: "GroupID",
      type: "string",
      label: "Product Group ID",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
    {
      key: "Type",
      type: "string",
      label: "Product Type",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
  ],
};
