import React, { useState, useEffect } from 'react';
import {
    useFlatfile,
    usePlugin,
    Workbook,
    Space,
    FlatfileProvider,
} from "@flatfile/react";
import { recordHook } from "@flatfile/plugin-record-hook";
import { salesDataWorkbook } from "../configs/workbook"; 
import './WorkbooksPage.css';
import { useNavigate } from 'react-router-dom';
import config from "./config";


const WorkbooksPage_sale = () => {
    const [selectedSidebar, setSelectedSidebar] = useState('Sales data'); // default to Reference data
    const PUBLISHABLE_KEY = 'pk_17dd17c7c44a4dbeb844920b883a3c05';
    const userSpaceId = 'us_sp_bQm4KgXM'; // Replace with dynamic user ID
    const navigate = useNavigate();
    const companyData = JSON.parse(localStorage.getItem("companyData"));
    const [dataSubmitted, setDataSubmitted] = useState(false);

    const handleSidebarClick = (sidebar) => {
        setSelectedSidebar(sidebar);
        // Change workbook based on the selected sidebar
        switch(sidebar) {
            case 'Reference data':
                navigate('/workbooks_ref');
                break;
            case 'Sales data':
                navigate('/workbooks_sale');
                break;
            case 'Service data':
                navigate('/workbooks_service');
                break;
            default:
                navigate('/workbooks_ref');
            }

    };
    
    const sendDataToApi = async (allSheetsData, companyData) => {
        try {
            console.log(JSON.stringify({ allSheetsData, companyData }));
    
            const response = await fetch(`${config.API_URL}/save-data.php`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ allSheetsData, companyData }),
            });
    
            const responseText = await response.text(); // Get raw response as text
            console.log("Raw Response Text:", responseText); // Log the raw response text
    
            if (!response.ok) throw new Error(`HTTP Error: ${response.status}`);
    
            let result;
            try {
                result = JSON.parse(responseText); // Try parsing the JSON response
            } catch (error) {
                console.error("Failed to parse response as JSON:", error);
                throw new Error("The server returned an invalid JSON response.");
            }
    
            if (result.status === "success") {
                console.log("Data saved successfully:", result.message);
            } else {
                console.error("Error saving data:", result.message);
            }
        } catch (error) {
            console.error("API request failed:", error);
        }
    };

    const SpaceConfig = () => {
        const { openPortal }                    = useFlatfile();
        const [records, setRecords]             = useState([]);
        const [allSheetsData, setAllSheetData]  = useState([]);
        const [invoiceLines, setInvoiceLines]   = useState([]);

        // Automatically open the portal when the component mounts
        useEffect(() => {
            openPortal();
        }, [openPortal]);

        usePlugin(
            recordHook("invoice_lines", (record) => {
                console.log("Processing Record:", record);    
                const invoiceLinesData = {
                    InvoiceNumber: record.get("InvoiceNumber"),
                    ClientBranchId: record.get("ClientBranchId"),
                    DeliveryDate: record.get("DeliveryDate"),
                    ProductId: record.get("ProductId"),
                    QuantitySold: record.get("QuantitySold"),
                    SellingPriceInClientCurrency: record.get("SellingPriceInClientCurrency"),
                };
                
                const recordId = record.rowId || record.get("rowId");
                if (!recordId) {
                    console.error("Record Missing rowId:", record);
                    return record;
                }
                invoiceLinesData.rowId = recordId;
        
                setInvoiceLines((prev) => {
                    console.log("Previous Invoice Lines State:", prev);
        
                    // Check if the record already exists
                    const recordExists = prev.some((invoiceLine) => invoiceLine.rowId === recordId);
                    if (recordExists) {
                        console.warn(`Record with rowId ${recordId} already exists. Skipping.`);
                        return prev; // Return the current state without changes
                    }
        
                    const updatedInvoiceLines = [...prev, invoiceLinesData];
        
                    console.log("Updated Invoice Lines Array:", updatedInvoiceLines);
                    // Update allSheetsData dynamically
                    setAllSheetData((prevAllSheetsData) => ({
                        ...prevAllSheetsData,
                        invoiceLines: updatedInvoiceLines,
                    }));
                    return updatedInvoiceLines;
                });
        
                return record;
            })
        );

        const handleSubmit = async (data) => {
         
            console.log("All Sheets Data: ", allSheetsData);
            console.log("Company Data", companyData);
            try {
                console.log("Proceeding to send data to API...");
                await sendDataToApi(allSheetsData, companyData);
                setDataSubmitted(true);
                console.log("Data pipeline completed successfully.");
            } catch (error) {
                console.error("Error in data pipeline:", error);
            }
        };  

        return (
            <div className="content">

                <Space
                    config={{
                        name: "Space",
                        spaceId: userSpaceId,
                        metadata: {
                            sidebarConfig: { showSidebar: false },
                            theme: { root: { actionColor: "#1C83CB" } },
                        },
                    }}
                >
                    <div className="workbooks-layout">
                        <Workbook
                            config={salesDataWorkbook}
                            onSubmit={handleSubmit}
                            onRecordHooks={[
                                [(record) => record],
                                [(record) => record],
                            ]}
                        />
                    </div>
                </Space>
            </div>
        );
    };

    return (
        
        <div className="workbooks-container">
            <div className="sidebar">
                <div className="icon-section">
                    <div 
                        className={`icon ${selectedSidebar === 'Reference data' ? 'active-sidebar' : ''}`} 
                        onClick={() => handleSidebarClick('Reference data')}
                    >
                        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 9C11.2044 9 10.4413 8.68393 9.87868 8.12132C9.31607 7.55871 9 6.79565 9 6C9 5.20435 9.31607 4.44129 9.87868 3.87868C10.4413 3.31607 11.2044 3 12 3C12.7956 3 13.5587 3.31607 14.1213 3.87868C14.6839 4.44129 15 5.20435 15 6C15 6.79565 14.6839 7.55871 14.1213 8.12132C13.5587 8.68393 12.7956 9 12 9ZM5.5 21C4.70435 21 3.94129 20.6839 3.37868 20.1213C2.81607 19.5587 2.5 18.7956 2.5 18C2.5 17.2044 2.81607 16.4413 3.37868 15.8787C3.94129 15.3161 4.70435 15 5.5 15C6.29565 15 7.05871 15.3161 7.62132 15.8787C8.18393 16.4413 8.5 17.2044 8.5 18C8.5 18.7956 8.18393 19.5587 7.62132 20.1213C7.05871 20.6839 6.29565 21 5.5 21ZM18.5 21C17.7044 21 16.9413 20.6839 16.3787 20.1213C15.8161 19.5587 15.5 18.7956 15.5 18C15.5 17.2044 15.8161 16.4413 16.3787 15.8787C16.9413 15.3161 17.7044 15 18.5 15C19.2956 15 20.0587 15.3161 20.6213 15.8787C21.1839 16.4413 21.5 17.2044 21.5 18C21.5 18.7956 21.1839 19.5587 20.6213 20.1213C20.0587 20.6839 19.2956 21 18.5 21Z" stroke="#0077BE" strokeWidth="1.5"/>
                            <path opacity="0.5" d="M20 13C20.0014 11.8648 19.7605 10.7424 19.2935 9.70776C18.8265 8.67309 18.1442 7.74995 17.292 7M4.00001 13C3.99866 11.8648 4.23952 10.7424 4.7065 9.70776C5.17348 8.67309 5.85584 7.74995 6.70801 7M10 20.748C10.6534 20.916 11.3254 21.0007 12 21C12.6746 21.0007 13.3466 20.916 14 20.748" stroke="#0077BE" strokeWidth="1.5" strokeLinecap="round"/>
                        </svg>

                    </div>

                    <div 
                        className={`icon center ${selectedSidebar === 'Sales data' ? 'active-sidebar' : ''}`} 
                        onClick={() => handleSidebarClick('Sales data')}
                    >
                        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.5" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0077BE" strokeWidth="1.5"/>
                            <path d="M12 17V18M12 17C13.657 17 15 15.88 15 14.5C15 13.12 13.657 12 12 12C10.343 12 9 10.88 9 9.5C9 8.12 10.343 7 12 7M12 17C10.343 17 9 15.88 9 14.5M12 6V7M12 7C13.657 7 15 8.12 15 9.5" stroke="#0077BE" strokeWidth="1.5" strokeLinecap="round"/>
                        </svg>

                    </div>

                    <div 
                        className={`icon invalid-count ${selectedSidebar === 'Service data' ? 'active-sidebar' : ''}`} 
                        onClick={() => handleSidebarClick('Service data')}
                    >
                        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.2702 16.265L20.9752 12.185C21.0166 11.9459 21.0052 11.7006 20.9419 11.4663C20.8786 11.232 20.7648 11.0144 20.6086 10.8287C20.4524 10.643 20.2575 10.4936 20.0376 10.391C19.8176 10.2885 19.5779 10.2352 19.3352 10.235H14.1532C14.0324 10.2349 13.913 10.2085 13.8034 10.1576C13.6938 10.1068 13.5966 10.0327 13.5185 9.94049C13.4404 9.8483 13.3833 9.74023 13.3511 9.62376C13.3189 9.50729 13.3125 9.38522 13.3322 9.26601L13.9952 5.22101C14.1023 4.56422 14.0717 3.89233 13.9052 3.24801C13.8333 2.9819 13.6954 2.73823 13.5044 2.5395C13.3133 2.34077 13.0753 2.1934 12.8122 2.11101L12.6672 2.06401C12.3396 1.95938 11.9845 1.9837 11.6742 2.13201C11.3342 2.29601 11.0862 2.59501 10.9942 2.95001L10.5182 4.78401C10.3669 5.36772 10.1467 5.93135 9.86223 6.46301C9.44623 7.24001 8.80423 7.86301 8.13723 8.43801L6.69723 9.67801C6.49759 9.85052 6.34168 10.0679 6.24225 10.3123C6.14283 10.5567 6.10272 10.8211 6.12523 11.084L6.93823 20.477C6.974 20.8922 7.16411 21.279 7.47105 21.5609C7.77798 21.8429 8.17945 21.9995 8.59623 22H13.2452C16.7262 22 19.6972 19.574 20.2692 16.265" fill="#0077BE"/>
                            <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M2.96808 9.485C3.16134 9.47655 3.35039 9.5431 3.49574 9.67075C3.64109 9.7984 3.7315 9.97727 3.74808 10.17L4.71808 21.406C4.73451 21.5733 4.71666 21.7422 4.66562 21.9024C4.61458 22.0626 4.53142 22.2107 4.42122 22.3377C4.31102 22.4647 4.17609 22.5678 4.02468 22.6409C3.87327 22.714 3.70855 22.7555 3.54058 22.7627C3.37261 22.77 3.20492 22.743 3.04775 22.6833C2.89058 22.6236 2.74723 22.5325 2.62646 22.4155C2.50568 22.2986 2.41002 22.1582 2.3453 22.003C2.28059 21.8479 2.24819 21.6811 2.25008 21.513V10.234C2.25016 10.0407 2.32488 9.85486 2.45866 9.71531C2.59244 9.57576 2.77494 9.49325 2.96808 9.485Z" fill="#0077BE"/>
                        </svg>

                    </div>
                </div>
            </div>

            <FlatfileProvider publishableKey={PUBLISHABLE_KEY}>
                <SpaceConfig />
            </FlatfileProvider>
        </div>
    );
};

export default WorkbooksPage_sale;
