import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react'; // Import useAuth0
import './LoginPage.css';

const LoginPage = () => {
    const { loginWithRedirect } = useAuth0(); // Auth0 login method

    return (
        <Container className="d-flex login-container">
            <Row className="w-100 justify-content-center">
                <Col xs={12} className="text-center">
                    <img src="/logo.png" alt="Pivotal Logo" className="logo" />
                    <h2>Optimising supply chains</h2>
                </Col>

                <Col xs={12} sm={10} md={8} lg={6}>
                    <Card className="shadow login-box">
                        <Card.Body>
                            <div className="d-flex justify-content-center">
                                <button
                                    className="btn btn-primary w-100 mt-3"
                                    onClick={() => loginWithRedirect()}
                                >
                                    Login with Auth0
                                </button>
                            </div>
                            <div className="d-flex justify-content-between mt-3">
                                <a href="https://www.pivotal-dss.com/" target="_blank" rel="noopener noreferrer">
                                    Don’t have an account
                                </a>
                                <a href="#" onClick={() => alert('Forgot Password Flow')}>Forgot password</a>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default LoginPage;
