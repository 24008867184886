export const client_branches = {
  name: "Client Branches",
  slug: "client_branches",
  fields: [
    {
      key: "ID",
      type: "string",
      label: "ID",
      constraints: [
        {
          "type": "unique"
        }
      ],
    },
    {
      key: "Name",
      type: "string",
      label: "Name",
    },
    {
      key: "Description",
      type: "string",
      label: "Description",
    },
    {
      key: "ClientID",
      type: "string",
      label: "Client ID",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
    {
      key: "ChannelID",
      type: "string",
      label: "Channel ID",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
    {
      key: "Location",
      type: "string",
      label: "Location",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
  ],
};
