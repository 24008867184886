export const clients = {
  name: "Clients",
  slug: "clients",
  fields: [
    {
      key: "ID",
      type: "string",
      label: "ID",
      constraints: [
        {
          "type": "unique"
        }
      ],
    },
    {
      key: "Name",
      type: "string",
      label: "Name",
    },
    {
      key: "Description",
      type: "string",
      label: "Description",
    },
    {
      key: "Currency",
      type: "string",
      label: "Currency",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
  ],
};
