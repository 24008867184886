export const invoice_lines = {
  name: "Invoice Lines",
  slug: "invoice_lines",
  fields: [
    {
      key: "InvoiceNumber",
      type: "string",
      label: "Invoice Number",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
    {
      key: "ClientBranchId",
      type: "string",
      label: "Client Branch ID",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
    {
      key: "DeliveryDate",
      type: "string",
      label: "Delivery Date",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
    {
      key: "ProductId",
      type: "string",
      label: "Product ID",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
    {
      key: "QuantitySold",
      type: "string",
      label: "Quantity Sold",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
    {
      key: "SellingPriceInClientCurrency",
      type: "string",
      label: "Selling Price In Client Currency",
      constraints: [
        {
          "type": "required"
        }
      ],
    },
  ],
};