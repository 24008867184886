import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import config from './components/config';

const domain = "auth0.ppt.pivotal-dss.com";
const clientId = "S5lzt3Z6tDKhSaUxBejmlI3I7g4r4HFY";

const Root = () => {
  const { user, isAuthenticated, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();
  const [companyData, setCompanyData] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Check if the data is already stored in localStorage
    const storedCompanyData = localStorage.getItem('companyData');
    const storedUserData = localStorage.getItem('userData');

    if (storedCompanyData && storedUserData) {
      try {
        // If data exists, parse and set it to state
        setCompanyData(JSON.parse(storedCompanyData));
        setUserData(JSON.parse(storedUserData));
      } catch (error) {
        console.error("Error parsing data from localStorage", error);
        localStorage.removeItem('companyData');
        localStorage.removeItem('userData');
      }
    }

    if (isAuthenticated && user && !storedCompanyData && !storedUserData) {
      validateUser(user.email);
    }
  }, [isAuthenticated, user]);

  const validateUser = async (email) => {
    try {
      const response = await fetch(`${config.API_URL}/validate_user.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (!data.success) {
        alert('Access denied. You are not authorized to use this application.');
        logout({ returnTo: window.location.origin });
        return;
      }

      try { // GETTING LOGO IMAGE FOR COMPANY
        const response_img = await fetch(`${config.API_URL}/get_logo.php?id=${data.company.id}`, { method: 'GET', });
      
        if (!response_img.ok) {
          throw new Error(`Failed to fetch logo: ${response_img.statusText}`);
        }
        const blob = await response_img.blob();
        const imageUrl = URL.createObjectURL(blob);
        data.company.company_logo = imageUrl;
      
      } catch (error) {
        console.error('Error fetching logo:', error);
      }
      
      setCompanyData(data.company);
      setUserData(data.users);

      // Store the data in localStorage for persistence
      localStorage.setItem('companyData', JSON.stringify(data.company));
      localStorage.setItem('userData', JSON.stringify(data.users));
    } catch (error) {
      console.error('Error validating user:', error);
    }
  };

  return <App />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      <Root />
    </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals();