import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './completeRegistration.css';
import config from './config';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Alert } from "react-bootstrap";

const CompleteRegistration = () => {
    const { user, isAuthenticated } = useAuth0();
    const { company_id, user_limit } = useParams(); // Get company_id and user_limit from URL params
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [logo, setLogo] = useState(null);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // State to manage form loader
    const [authLoading, setAuthLoading] = useState(true); // State to manage authorization loader
    const [accessDenied, setAccessDenied] = useState(false);

    useEffect(() => {
        if (isAuthenticated && user?.email) {
            axios
                .get(`${config.API_URL}/admin_rights.php`, { params: { email: user.email } })
                .then((response) => {
                    if (response.data.success) {
                        setAccessDenied(false);
                    } else {
                        setAccessDenied(true);
                        setError(response.data.error || 'Access denied.');
                    }
                })
                .catch((err) => {
                    console.error("Error checking admin rights:", err);
                    setAccessDenied(true);
                    setError('Error occurred while checking access rights.');
                })
                .finally(() => {
                    setAuthLoading(false);
                });
        } else {
            setAuthLoading(false);
            setAccessDenied(true);
        }
    }, [isAuthenticated, , user, company_id, user_limit]);
    

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email || !firstName || !lastName || !logo) {
            setError('All fields are required.');
            return;
        }

        setLoading(true);
        setError('');
        setMessage('');

        try {
            const formData = new FormData();
            formData.append('email', email);
            formData.append('first_name', firstName);
            formData.append('last_name', lastName);
            formData.append('logo', logo);
            formData.append('company_id', company_id);
            formData.append('user_limit', user_limit);

            const response = await axios.post(`${config.API_URL}/complete_registration.php`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (response.data.success) {
                setMessage('Registration completed successfully!');
                setEmail('');
                setFirstName('');
                setLastName('');
                setLogo(null);
            } else {
                setError(response.data.error || 'An error occurred.');
            }
        } catch (err) {
            console.error('Error during registration:', err);
            setError('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    if (authLoading) {
        // Show loader while checking authorization
        return (
            <Container className="text-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <p>Checking authorization, please wait...</p>
            </Container>
        );
    }

    if (accessDenied) {
        return (
            <Container className="text-center mt-5">
                <Alert variant="danger">
                    You do not have access to view this page. <br />
                    Please log in with <strong> support@pivotal-dss.com</strong> to proceed.
                </Alert>
            </Container>
        );
    }

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card shadow">
                        <div className="card-header bg-primary text-white text-center">
                            <h3>Complete Registration</h3>
                        </div>
                        <div className="card-body">
                            {loading ? (
                                <div className="text-center">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <p>Please wait...</p>
                                </div>
                            ) : (
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">
                                            Company Email
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            placeholder="Enter your company email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="firstName" className="form-label">
                                            First Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="firstName"
                                            placeholder="Enter your first name"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="lastName" className="form-label">
                                            Last Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="lastName"
                                            placeholder="Enter your last name"
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="logo" className="form-label">
                                            Company Logo
                                        </label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            id="logo"
                                            onChange={(e) => setLogo(e.target.files[0])}
                                            accept="image/png, image/jpeg"
                                            required
                                        />
                                        <small className="form-text text-muted">
                                            Only PNG or JPG files are allowed.
                                        </small>
                                    </div>
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    {message && <div className="alert alert-success">{message}</div>}
                                    <button type="submit" className="btn btn-primary w-100">
                                        Submit
                                    </button>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompleteRegistration;
