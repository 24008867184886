import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginPage from './components/LoginPage';
import DashboardPage from './components/DashboardPage';
import SettingsPage from './components/SettingsPage';
import WorkbooksPage_ref from './components/WorkbooksPage_ref';
import WorkbooksPage_sale from './components/WorkbooksPage_sale';
import WorkbooksPage_service from './components/WorkbooksPage_service';
import ReportsPage from './components/ReportsPage';
import Topbar from './components/Topbar';
import CompleteRegistration from './components/CompleteRegistration';
import DatabaseManagementPage from './components/DatabaseManagementPage';
import CurrenciesPage from './components/CurrenciesPage';

import './App.css';

const AppWrapper = () => (
    <Router>
        <App />
    </Router>
);

const App = () => {
    const { isAuthenticated, user, isLoading, loginWithRedirect, logout, error } = useAuth0();
    const location = useLocation(); // Get the current route

    const isCompleteRegistration = location.pathname.startsWith('/complete-registration');

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div className={`App ${!isAuthenticated && !isCompleteRegistration ? 'login-background' : ''}`}>
            {!isAuthenticated ? (
                <Routes>
                    <Route path="/complete-registration/:company_id/:user_limit" element={<CompleteRegistration />} />
                    <Route path="*" element={<LoginPage />} />
                </Routes>
            ) : (
                <>
                    <Topbar
                        user={user?.name}
                        profilePicture={user?.picture}
                        setAuth={() => logout({ returnTo: window.location.origin })}
                    />
                    <div className="content">
                        <Routes>
                            <Route path="/" element={<DashboardPage user={user?.name} profilePicture={user?.picture} />} />
                            <Route path="/dashboard" element={<DashboardPage user={user?.name} profilePicture={user?.picture} />} />
                            <Route path="/settings" element={<SettingsPage user={user?.name} />} />
                            <Route path="/workbooks_ref" element={<WorkbooksPage_ref user={user?.name} />} />
                            <Route path="/workbooks_sale" element={<WorkbooksPage_sale user={user?.name} />} />
                            <Route path="/Workbooks_service" element={<WorkbooksPage_service user={user?.name} />} />
                            <Route path="/reports" element={<ReportsPage user={user?.name} />} />
                            <Route path="/db-management" element={<DatabaseManagementPage user={user?.name} />} />
                            <Route path="/currencies" element={<CurrenciesPage user={user?.name} />} />
                            <Route path="/complete-registration/:company_id/:user_limit" element={<CompleteRegistration />} />
                        </Routes>
                    </div>
                </>
            )}
        </div>
    );
};

export default AppWrapper;
