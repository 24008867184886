import React from 'react';
import './Box.css';

const Box = ({ title, content, color, svg, onClick }) => {
    // Conditionally add the "disable" class based on the title
    const isDisabled = title === 'Sales Planning' || title === 'Operations Planning';

    return (
        <div className={`box ${color} ${isDisabled ? 'disable' : ''}`} onClick={!isDisabled ? onClick : null} style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}>
            {/* Separate div for disabled boxes */}
            {isDisabled && <div className="disabled-overlay"></div>}

            <h4>{title}</h4>
            <p>
                <img src={svg} alt={title} />
                {content}
            </p>
        </div>
    );
};

export default Box;
